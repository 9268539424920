import "./src/styles/global.css";
import RootLayout from "./src/components/root-layout";
import * as React from "react";
import type { GatsbyBrowser } from "gatsby";

export const wrapRootElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}: {
  element: React.ReactNode;
}) => <RootLayout>{element}</RootLayout>;
